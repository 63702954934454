.required::after {
  content: " *";
  color: #ff0000;
}
.modal-backdrop.fade.show {
  opacity: 0.2;
}
.spinner {
  margin-right: 6px;
}
.input-text-style {
  text-transform: capitalize;
}
.form-label {
  margin-bottom: 4px;
  margin-left: 2px;
  font-size: 14px;
}
.form-check-label {
  font-size: 14px;
}
.alert {
  margin-bottom: 8px;
  --bs-alert-padding-x: 6px;
  --bs-alert-padding-y: 6px;
  font-size: 14px;
}

.main-wrapper {
  height: 100vh;
  display: grid;
  grid-template-columns: 400px auto;
}

@media (max-width: 320px) {
  .main-wrapper {
    display: grid;
    grid-template-columns: 60% auto;
  }
}
