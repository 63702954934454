.App {
  font-family: Montserrat, Helvetica, Arial, serif;
  font-size: 16px;
  font-weight: 400;
  height: 100%;
  letter-spacing: 0.5px;
  line-height: 1.5;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
