.icon {
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: top;
}

.icon-large {
  @extend .icon;
  width: 48px;
  height: 48px;
  background-size: 48px;
}

.icon-medium {
  @extend .icon;
  width: 24px;
  height: 24px;
  // background-size: 48px;
}

.icon-small {
  @extend .icon;
  width: 12px;
  height: 12px;
  background-size: 12px;
}
.icon-14 {
  @extend .icon;
  width: 14px;
  height: 14px;
  background-size: 14px;
}

.icon-4 {
  @extend .icon;
  width: 4px;
  height: 4px;
}

.icon-8 {
  @extend .icon;
  width: 8px;
  height: 8px;
}

.icon-12 {
  @extend .icon;
  width: 12px;
  height: 12px;
  background-size: 12px;
}

.icon-16 {
  @extend .icon;
  width: 16px;
  height: 16px;
  // background-size: 16px;
}
.icon-18 {
  @extend .icon;
  width: 18px;
  height: 18px;
  // background-size: 16px;
}
.icon-20 {
  @extend .icon;
  width: 20px;
  height: 20px;
  background-size: 20px;
}

.icon-24 {
  @extend .icon;
  width: 24px;
  height: 24px;
  background-size: 24px;
}

.icon-26 {
  @extend .icon;
  width: 26px;
  height: 26px;
  background-size: 26px;
}

.icon-30 {
  @extend .icon;
  width: 30px;
  height: 30px;
  background-size: 30px;
}

.icon-32 {
  @extend .icon;
  width: 32px;
  height: 32px;
  background-size: 32px;
}

.icon-36 {
  @extend .icon;
  width: 36px;
  height: 36px;
  background-size: 36px;
}
.icon-42 {
  @extend .icon;
  width: 42px;
  height: 42px;
  background-size: 42px;
}

.icon-48 {
  @extend .icon;
  width: 48px;
  height: 48px;
  background-size: 48px;
}

.icon-50 {
  @extend .icon;
  width: 50px;
  height: 50px;
  background-size: 50px;
}

.icon-72 {
  @extend .icon;
  width: 72px;
  height: 72px;
  background-size: 72px;
}
.icon-lock {
  @extend .icon;
  background-image: url("../assets/icons/lock-icon.svg");
}
.icon-clock {
  @extend .icon;
  background-image: url("../assets/icons/clock-regular.svg");
}
.icon-email {
  @extend .icon;
  background-image: url("../assets/icons/envelope-solid.svg");
}
.icon-show-more {
  @extend .icon;
  background-image: url("../assets/icons/show-more.svg");
}
.icon-edit {
  @extend .icon;
  background-image: url("../assets/icons/pen-solid.svg");
}
.icon-delete {
  @extend .icon;
  background-image: url("../assets/icons/trash-can-solid.svg");
}
.icon-heart {
  @extend .icon;
  background-image: url("../assets/icons/heart-solid.svg");
}
.icon-close {
  @extend .icon;
  background-image: url("../assets/icons/xmark-solid.svg");
}
.icon-power-button {
  @extend .icon;
  background-image: url("../assets/icons/power-button.svg");
}
.icon-download {
  @extend .icon;
  background-image: url("../assets/icons/download-solid.svg");
}
.icon-left-arrow {
  @extend .icon;
  background-image: url("../assets/icons/arrow-left-solid.svg");
}
.icon-left-arrow:hover {
  @extend .icon;
}
